var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.$route.fullPath !== '/thank-you' &&
      _vm.$route.name !== 'landing-page.watchvideo'
    )?_c('NavHeader',{attrs:{"items":_vm.headerItem,"userprofile":_vm.profile,"homeURL":{ name: 'dashboard.home-1' },"logo":_vm.logo}},[_c('template',{slot:"responsiveRight"},[_c('ul',{staticClass:"d-flex align-items-center justify-content-end list-inline m-0"})])],2):_vm._e(),(this.$route.meta.slider === 'true' || this.$route.meta.movieslider)?_c('Home',{attrs:{"id":"home"}}):_vm._e(),(this.$route.meta.category)?_c('Slider'):_vm._e(),(this.$route.name == 'landing-page.show-single')?_c('Banner'):_vm._e(),(
      this.$route.name == 'landing-page.about' ||
      this.$route.name == 'landing-page.contact' ||
      this.$route.name == 'landing-page.privacypolicy' ||
      this.$route.name == 'landing-page.pricingplan1' ||
      this.$route.name == 'landing-page.pricingplan2' ||
      this.$route.name == 'landing-page.faq' ||
      this.$route.name == 'landing-page.blog' ||
      this.$route.name == 'landing-page.blogdetail' ||
      this.$route.name == 'landing-page.pricing-planning'
    )?_c('Breadcrumb'):_vm._e(),_c('div',{class:this.$route.meta.mainClass == '' ? '' : 'main-content'},[_c('router-view',{key:_vm.$route.path})],1),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"back-to-top"}},[_c('a',{staticClass:"top",attrs:{"href":"#top","id":"top"}},[_c('i',{staticClass:"fa fa-angle-up"})])])
}]

export { render, staticRenderFns }