<template>
  <div>
    <!-- <Loader /> -->
    <NavHeader
      :items="headerItem"
      :userprofile="profile"
      :homeURL="{ name: 'dashboard.home-1' }"
      :logo="logo"
      v-if="
        $route.fullPath !== '/thank-you' &&
        $route.name !== 'landing-page.watchvideo'
      "
    >
      <template slot="responsiveRight">
        <ul
          class="d-flex align-items-center justify-content-end list-inline m-0"
        ></ul>
      </template>
    </NavHeader>
    <Home
      id="home"
      v-if="this.$route.meta.slider === 'true' || this.$route.meta.movieslider"
    />
    <Slider v-if="this.$route.meta.category" />
    <!-- <BannerVideo
      v-if="
        this.$route.name == 'landing-page.movie-detail' ||
          this.$route.name == 'landing-page.category-detail'
      "
    /> -->
    <Banner v-if="this.$route.name == 'landing-page.show-single'" />
    <Breadcrumb
      v-if="
        this.$route.name == 'landing-page.about' ||
        this.$route.name == 'landing-page.contact' ||
        this.$route.name == 'landing-page.privacypolicy' ||
        this.$route.name == 'landing-page.pricingplan1' ||
        this.$route.name == 'landing-page.pricingplan2' ||
        this.$route.name == 'landing-page.faq' ||
        this.$route.name == 'landing-page.blog' ||
        this.$route.name == 'landing-page.blogdetail' ||
        this.$route.name == 'landing-page.pricing-planning'
      "
    />
    <div :class="this.$route.meta.mainClass == '' ? '' : 'main-content'">
      <!-- <transition
        name="router-anim"
        :enter-active-class="`animated ${animated.enter}`"
        mode="out-in"
        :leave-active-class="`animated ${animated.exit}`"
      > -->
      <router-view :key="$route.path" />
      <!-- </transition> -->
    </div>
    <!-- <Footer v-if="$route.name!=='landing-page.watchvideo'"/> -->
    <div id="back-to-top">
      <a class="top" href="#top" id="top"> <i class="fa fa-angle-up"></i> </a>
    </div>
  </div>
</template>
<script>
import { core } from '../config/pluginInit'
// import Loader from '../components/core/loader/Loader'
import profile from '../assets/images/frontend/user/user.jpg'
import loader from '../assets/images/logo.png'
// import Footer from '../views/FrontendPages/Components/Footer/Footer'
import Home from '../views/FrontendPages/Components/Home/Home'
import NavHeader from '../components/core/navbars/FrontendNav'
// import BannerVideo from '../views/FrontendPages/MovieDetailPage/BannerVideo'
import Breadcrumb from '../views/FrontendPages/Components/Breadcrumb/Breadcrumb'
export default {
  name: 'BackendLayout',
  components: {
    // Footer,
    // Loader,
    Home,
    NavHeader,
    // BannerVideo,
    Breadcrumb
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      profile: '',
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      userProfile: profile,
      onlyLogo: false,
      onlyLogoText: false,
      logo: loader,
      headerItem: [
        { title: 'Home', link: '/', child: false },
        { title: 'Movies', link: '/movie-category', child: false },
        { title: 'Tv Shows', link: '/show-category', child: false },
        {
          title: 'pages',
          link: '#',
          child: true,
          children: [
            {
              title: 'About Us',
              link: '/about',
              grandchild: false
            },
            {
              title: 'Contact',
              link: '/contact',
              grandchild: false
            },
            {
              title: 'Privacy-Policy',
              link: '/privacy-policy',
              grandchild: false
            }
          ]
        }
      ]
    }
  },
  methods: {
    changeLogo (e) {
      this.logo = e
    },
    routerAnimationChange (e) {
      this.animated = e
    }
  }
}
</script>
<style lang="scss">
@import url('../assets/css/custom.css');
@import url('../assets/css/developer.css');
@import '../assets/scss/frontend.scss';
</style>

<style>
@media (max-width: 425px) {
  .big-title {
    font-size: 2.5em !important;
  }
}
</style>
