<template>
  <!-- TOP Nav Bar -->
  <header id="main-header">
    <div class="main-header">
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
              <a
                href="javascript:void(0)"
                class="navbar-toggler c-toggler collapsed"
                @click="openSidebar"
              >
                <div class="navbar-toggler-icon" data-toggle="collapse">
                  <span class="navbar-menu-icon navbar-menu-icon--top"></span>
                  <span
                    class="navbar-menu-icon navbar-menu-icon--middle"
                  ></span>
                  <span
                    class="navbar-menu-icon navbar-menu-icon--bottom"
                  ></span>
                </div>
              </a>
              <router-link class="navbar-brand" to="/">
                <img class="img-fluid logo" :src="logo" alt="HighFit" />
              </router-link>
              <b-collapse id="navbarSupportedContent" is-nav :visible="sidebar">
                <div class="menu-main-menu-container" v-if="categories.length">
                  <ul id="top-menu" class="navbar-nav ml-auto">
                    <li class="menu-item">
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'en'">
                        Home
                      </a>
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'ku'">
                        سەرەکی
                      </a>
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'ar'">
                        الرئيسية</a
                      >
                    </li>

                    <li
                      v-for="(item, index) in categories"
                      :key="index"
                      class="menu-item"
                    >
                      <a @click="gotoPage('/category/' + item.id)">{{
                        item.name?.[$i18n.locale]
                      }}</a>
                    </li>

                    <li class="menu-item">
                      <a
                        @click="gotoPage('/myVideos/')"
                        v-if="$i18n.locale == 'en'"
                      >
                        My Videos
                      </a>
                      <a
                        @click="gotoPage('/myVideos/')"
                        v-if="$i18n.locale == 'ku'"
                      >
                        ڤیدیۆکانم
                      </a>
                      <a
                        @click="gotoPage('/myVideos/')"
                        v-if="$i18n.locale == 'ar'"
                        >فيديوهاتي</a
                      >
                    </li>
                    <!-- <li class="menu-item">
                      <a
                        @click="gotoPage('/terms-and-conditions/')"
                        v-if="$i18n.locale == 'en'"
                      >
                        Terms And Conditions
                      </a>
                      <a
                        @click="gotoPage('/terms-and-conditions/')"
                        v-if="$i18n.locale == 'ku'"
                      >
                        بەند و مەرجەکان
                      </a>
                      <a
                        @click="gotoPage('/terms-and-conditions/')"
                        v-if="$i18n.locale == 'ar'"
                      >
                        شروط وأحكام الخدمة</a
                      >
                    </li> -->

                    <!-- profile -->
                    <li class="menu-item" v-if="showLogout()">
                      <a
                        v-if="$i18n.locale == 'en'"
                        @click="gotoPage('/profile')"
                        >Profile</a
                      >
                      <a
                        v-if="$i18n.locale == 'ku'"
                        @click="gotoPage('/profile')"
                        >Profile</a
                      >
                      <a
                        v-if="$i18n.locale == 'ar'"
                        @click="gotoPage('/profile')"
                        >الملف الشخصي</a
                      >
                    </li>

                    <!-- logout action -->
                    <li class="menu-item" v-if="showLogout()">
                      <a v-if="$i18n.locale == 'en'" @click="logout()"
                        >LOGOUT</a
                      >
                      <a v-if="$i18n.locale == 'ku'" @click="logout()"
                        >LOGOUT</a
                      >
                      <a v-if="$i18n.locale == 'ar'" @click="logout()"
                        >تسجيل الخروج</a
                      >
                    </li>
                  </ul>
                </div>
              </b-collapse>
              <div class="navbar-right">
                <div v-if="$i18n.locale == 'en'">Points : {{ userPoints }}</div>
                <div v-if="$i18n.locale == 'ku'">
                  {{ userPoints }} : خاڵەکانم
                </div>
                <div v-if="$i18n.locale == 'ar'">{{ userPoints }} : نقاط</div>
              </div>

              <div class="dropdown" v-show="sidebar == false">
                <button class="dropbtn">
                  <span style="font-size: 18px">
                    <span v-if="$i18n.locale == 'en' || $i18n.locale == null">
                      English
                    </span>
                    <span v-if="$i18n.locale == 'ku'"> کوردی </span>
                    <span v-if="$i18n.locale == 'ar'"> عربی </span>
                    <i class="fas fa-caret-down"></i>
                  </span>
                </button>

                <div class="dropdown-content" v-show="sidebar == false">
                  <span v-if="showKurdish()" @click="changeLang('ku')"
                    >کوردی</span
                  >
                  <span @click="changeLang('ar')">عربی</span>
                  <span @click="changeLang('en')">English</span>
                </div>
              </div>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </header>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { core, APPLOGONAME } from '../../../config/pluginInit'
import ApiService from '../../../services/api'

export default {
  name: 'FrontendNav',
  props: {
    logo: {
      type: String,
      default: require('../../../assets/images/appLogo.png')
    },
    items: { type: Array },
    userprofile: { type: String }
  },
  data () {
    return {
      appName: APPLOGONAME,
      sidebar: false,
      categories: [],
      isSelectLanguage: true
    }
  },
  mounted () {
    this.$i18n.locale = localStorage.getItem('lang') ?? 'en'
    core.index()
    this.getCategories()
  },
  methods: {
    changeLang (lang) {
      location.reload()
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      ApiService.changeLAng()
      this.$http.defaults.headers.common['lang'] = localStorage.getItem('lang')
    },
    gotoPage (link) {
      document.getElementsByTagName('body')[0].classList.remove('nav-open')
      this.sidebar = false
      this.$router.push(link)
    },
    openSidebar () {
      if (this.sidebar === false) {
        document.getElementsByTagName('body')[0].classList.add('nav-open')
        this.sidebar = true
      } else {
        document.getElementsByTagName('body')[0].classList.remove('nav-open')
        this.sidebar = false
      }
    },
    getCategories () {
      ApiService.getCategories().then((response) => {
        this.categories = response.data.data
      })
    },
    showKurdish () {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode === '964' || countryCode === 'IQ'
    },
    showLogout () {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode === '971'
    },
    logout () {
      localStorage.removeItem('highFitToken')
      this.$router.push('/msisdn-login')
    }
  },
  computed: {
    userPoints () {
      return this.$store.state.points
    }
  }
}
</script>

<style>
.dropbtn {
  border: none;
  background-color: transparent;
  color: #f0eeee;
  margin-top: 0px;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f0f0f0;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(145, 42, 42, 0.2);
  z-index: 1;
  right: -13px;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: v-bind(primaryColor);
  color: #ffffff;
  transition: 0.3s;
  background-color: #486069;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  transition: 0.5s;
  background-color: v-bind(primaryColor);
}

@media (max-width: 567px) {
  .navbar-right {
    font-size: 13px;
    padding: 10px;
    margin-right: -150px;
    margin-bottom: 25px;
  }
}
</style>
